<template>
  <!-- if it has children, make it accordion -->
  <div class="flex flex-col gap-y-2" v-if="children && children.length > 0">
    <div class="flex items-center gap-x-2" @click="isOpen = !isOpen">
      <span :class="isSmall ? 'text-base' : 'text-xl'">
        {{ title }}
      </span>
      <Icon
        :icon="!isOpen ? 'mdi:chevron-down' : 'mdi:chevron-up'"
        class="text-white text-2xl"
      ></Icon>
    </div>
    <div class="flex flex-col gap-y-6 sm:ps-6 ps-4 pt-6" v-if="isOpen">
      <OverlayMenuItem
        v-for="(item, index) in children"
        :key="index"
        v-bind="item"
        :is-small="true"
      />
    </div>
  </div>
  <!-- if it has src, make it an image -->
  <NuxtLink :to="link" class="flex items-center gap-x-2" v-else-if="src">
    <NuxtPicture
      :src="src"
      :alt="title"
      class="w-6 h-6"
      height="24"
      width="24"
      format="svg"
    ></NuxtPicture>
    <span :class="isSmall ? 'text-base' : 'text-xl'">{{ title }}</span>
  </NuxtLink>
  <!-- if it has link, make it a link -->
  <NuxtLink :to="link" class="flex items-center gap-x-2" v-else>
    <span :class="isSmall ? 'text-base' : 'text-xl'">
      {{ title }}
    </span>
  </NuxtLink>

  <!-- <NuxtLink
    :to="link"
    class="flex items-center gap-x-2 p-2 text-lg"
    v-if="link"
    >{{ title }}</NuxtLink
  > -->
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue";
import type { PropType } from "vue";

defineProps({
  title: {
    type: String,
    required: true,
  },
  link: {
    type: String,
  },
  children: {
    type: Array as PropType<
      {
        title: string;
        link: string;
        src: string;
      }[]
    >,
  },
  src: {
    type: String,
  },
  isSmall: {
    type: Boolean,
    default: false,
  },
});

const isOpen = ref(true);
</script>

<style scoped lang="scss">
a {
  @apply text-lg text-white;
  & .router-link-active,
  &.router-link-exact-active {
    @apply underline;
  }
}
</style>
